export const system = 8;

export const systemTitle = '年报通' //'生活'//

export default [
  {
    appName: "怡信",
    telephone: "18973819131",
    mpUrl: "https://51w.co/chhOl",
    company: "娄底怡信信息咨询有限公司",
    icp: "湘ICP备2023010289号-1",
  },
  {
    appName: "千里",
    telephone: "13142239783",
    mpUrl: "https://z.9i5.cc/0000",
    company: "湖南志在千里科技有限公司",
    icp: "湘ICP备2023025621号-2",
  },
  {
    appName: "凭信",
    telephone: "13751262079",
    mpUrl: "https://w.9i5.cc/0000",
    company: "湖南凭信网络科技有限公司",
    icp: "湘ICP备2023025693号-2",
  },
  {
    appName: "佐信",
    telephone: "13874835030",
    mpUrl: "https://m.z8x.co/0000",
    company: "湖南省佐信科技有限公司",
    icp: "湘ICP备2023030643号-2",
    h5Url: 'https://z8x.co/h5'
  },
  {
    appName: "晨希",
    telephone: "15773816683",
    mpUrl: "https://m.d9x.co/0000",
    company: "娄底市晨希网络科技有限公司",
    icp: "湘ICP备2023033409号"
  },
  {
    appName: "立腾",
    telephone: "18627619950",
    mpUrl: "https://m.lt8.co/0000",
    company: "娄底市立腾网络科技有限公司",
    icp: "湘ICP备2023031984号-2",
  },
  {
    appName: "弘祥",
    telephone: "13822156962",
    mpUrl: "https://m.h9x.co/0000",
    company: "长沙弘祥生物科技有限公司",
    icp: "湘ICP备2023039504号-2",
  },
  {
    appName: "昀信",
    telephone: "13397381077",
    mpUrl: "https://m.yx3.co/0000",
    company: "湖南昀信科技有限公司",
    icp: "湘ICP备2024043471号-2",//"湘ICP备2023039504号-2",
    h5Url: 'https://yx3.co/h5'
  },
  {
    appName: "升瀚",
    telephone: "13397381077",
    // mpUrl: "https://m.yx3.co/0000",
    company: "娄底市娄星区速腾网络科技有限公司",
    icp: "湘ICP备2024084934号-1",//"湘ICP备2023039504号-2",
    h5Url: 'https://sut8.cn/h5'
  },
];
